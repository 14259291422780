<template>
  <div
    class="comic-book-parent"
    hiddenX
  >
    <!-- COMIC BOOK TRANSITION -->
    <transition
      name="comicBookTransition"
      appear
    >
      <!-- COMIC BOOK -->
      <div
        v-if="dynamicKey"
        :key="dynamicKey"
        :class="dynamicClass"
      >
        <!-- BACK COVER  -->
        <span class="back-cover">
          <span class="back-cover-page-1"></span>
          <span class="back-cover-page-2"></span>
        </span>

        <!-- FRONT COVER COLOR -->
        <span class="front-cover-color"></span>

        <!-- FRONT COVER TEXTURE -->
        <span class="front-cover-texture"></span>

        <!-- FRONT COVER IMAGE -->
        <span class="front-cover-image"></span>

        <!-- FRONT COVER PRICE STICKER -->
        <span
          class="front-cover-price-sticker animated bounceIn"
          :style="stickerStyle"
        >
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { get as _get } from "lodash";

  export default {
    data() {
      return {
        dynamicKey: null,
        timeout: null,
      };
    },
    computed: {
      ...mapGetters({
        currentScene: "scenes/getCurrentScene",
        getSceneTransitionStatus: "scenes/getSceneTransitionStatus",
      }),
      // currentSceneName() {
      // 	return this.currentScene.data.value;
      // },
      audio() {
        return this.currentScene.data.sceneData.stinger.audio;
      },
      dynamicClass() {
        return "comic-book " + _get(this.currentScene, "data.sceneData.stinger.class", "");
      },
      stickerStyle() {
        if (
          _get(this.currentScene.data, "sceneData.stinger.stickerTop") &&
          _get(this.currentScene.data, "sceneData.stinger.stickerLeft")
        ) {
          return {
            top: this.currentScene.data.sceneData.stinger.stickerTop + "px",
            left: this.currentScene.data.sceneData.stinger.stickerLeft + "px",
          };
        }
      },
    },
    watch: {
      // Use currentSceneName if wanting to prevent pre-active transitions from activating again.
      currentScene: {
        immediate: true,
        handler(newVal, oldVal) {
          if (!!newVal && newVal !== oldVal) {
            if (_get(newVal, "data.sceneData.stinger", "")) {
              this.setAndRemoveKey();
            }
          }
        },
      },
    },
    methods: {
      setAndRemoveKey() {
        this.$store.dispatch("scenes/setSceneTransitionStatus", true);

        // Create dynamic key for removal after delay.
        this.dynamicKey = this.currentScene.data.sceneData.stinger.class;
        clearTimeout(this.timeout);

        // Throw static then logo.
        this.$store.dispatch("tasks/runTasks", [
          {
            uuid: "c406a414-ba01-44f6-804a-2f7f3a8ff565",
            delayStart: 2000,
          },
        ]);

        // Sound effect
        if (this.audio) this.$playSound(this.audio, { interrupt: true, volume: 0.3 });

        // Reset key to null - removes stinger.
        this.timeout = setTimeout(() => {
          // Broadcast the stringer is inactive.
          this.$store.dispatch("scenes/setSceneTransitionStatus", false);

          this.dynamicKey = null;
        }, 3000);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .comicBookTransition-enter-active,
  .comicBookTransition-leave-active {
    transition: transform 1s ease 300ms;
  }

  .comicBookTransition-enter,
  .comicBookTransition-leave-to {
    transform: translateX(300px) translateY(130%) rotate(20deg) !important; // [LOCKED]
  }

  .blurredBgActive {
    animation-delay: 400ms;
  }

  .blurredBgLeave {
    animation-delay: 400ms;
  }

  //-----------------------------------------------------------------
  // COMIC BOOK PARENT
  //-----------------------------------------------------------------

  .comic-book-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 2px dashed red;
    pointer-events: none;
    // z-index: 4;
  }

  //-----------------------------------------------------------------
  // COMIC BOOK
  //-----------------------------------------------------------------

  .comic-book {
    position: absolute;
    bottom: rem-calc(-180); // -165
    left: calc(50% - #{rem-calc(1030/2)});
    width: rem-calc(1030);
    height: rem-calc(1097);
    transform: translateX(0) translateY(0) rotate(5deg);
    // border: 1px dashed red;

    z-index: 4;

    .book-shadow,
    .book-shadow-1,
    .book-shadow-2,
    .back-cover,
    .back-cover-page-1,
    .back-cover-page-2,
    .front-cover-color,
    .front-cover-image,
    .front-cover-price-sticker,
    .front-cover-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .back-cover {
      top: rem-calc(5);
      left: auto;
      right: rem-calc(-14);
      box-shadow: -10px -2px 30px 6px rgba(black, 0.4);

      .back-cover-page-1 {
        top: rem-calc(10);
        left: auto;
        right: rem-calc(5);
        background-color: #9c9c9c;
      }

      .back-cover-page-2 {
        top: rem-calc(6);
        left: auto;
        right: rem-calc(10);
        background-color: #cccccc;
      }
    }

    .front-cover-texture {
      background-image: url("./assets/img/book-cover-texture--dark@2x.jpg");
      mix-blend-mode: overlay; //multiply;
      opacity: 0.9;
    }

    .front-cover-price-sticker {
      width: rem-calc(96/2);
      height: rem-calc(96/2);
      background-image: url("./assets/img/front-cover--10c@2x.png");
      background-size: rem-calc(96/2);
      animation-delay: 1400ms;
    }

    .front-cover-image {
      animation: enter-book-layer-2 1.7s ease 300ms alternate 1 forwards;
      will-change: transform;

      @keyframes enter-book-layer-2 {
        0% {
          transform: translateY(200px);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }

  //-----------------------------------------------------------------
  // MODIFIERS
  //-----------------------------------------------------------------

  .is-chamber-of-chat {
    $key-color: #444859;
    .back-cover {
      background-color: darken($key-color, 15%);
    }
    .front-cover-color {
      background-image: radial-gradient(
        54% 50%,
        #{saturate(lighten($key-color, 11%), 2%)} 54%,
        #{$key-color} 100%
      );
    }
    .front-cover-image {
      background-image: url("./assets/img/front-cover--chamber-of-chat@2x.png");
    }
  }

  .is-code-mode {
    $key-color: #407a6b;
    .back-cover {
      background-color: darken($key-color, 15%);
    }
    .front-cover-color {
      background-image: radial-gradient(
        54% 50%,
        #{saturate(lighten($key-color, 5%), 8%)} 54%,
        #{$key-color} 100%
      );
    }
    .front-cover-image {
      background-image: url("./assets/img/front-cover--code-mode@2x.png");
    }
  }

  .is-focus-mode {
    $key-color: #9a816e; //lighten(#9a816e, 2%);
    .back-cover {
      background-color: darken($key-color, 15%);
    }
    .front-cover-color {
      background-image: radial-gradient(
        54% 50%,
        #{saturate(lighten($key-color, 10%), 8%)} 54%,
        #{$key-color} 100%
      );
    }
    .front-cover-image {
      background-image: url("./assets/img/front-cover--focus-mode@2x.png");
    }
  }

  .is-creep-show-and-tell {
    $key-color: desaturate(#70587c, 5%);
    .back-cover {
      background-color: darken($key-color, 15%);
    }
    .front-cover-color {
      background-image: radial-gradient(
        54% 50%,
        #{saturate(lighten($key-color, 10%), 8%)} 54%,
        #{$key-color} 100%
      );
    }
    .front-cover-image {
      background-image: url("./assets/img/front-cover--creep-show-and-tell@2x.png");
    }
  }

  .is-ghoulish-games {
    $key-color: desaturate(#5a8a59, 10%);
    .back-cover {
      background-color: darken($key-color, 15%);
    }
    .front-cover-color {
      background-image: radial-gradient(
        54% 50%,
        #{saturate(lighten($key-color, 10%), 8%)} 54%,
        #{$key-color} 100%
      );
    }
    .front-cover-image {
      background-image: url("./assets/img/front-cover--ghoulish-games@2x.png");
    }
  }

  .is-the-vault-of-design {
    $key-color: #9e434d;
    .back-cover {
      background-color: darken($key-color, 15%);
    }
    .front-cover-color {
      background-image: radial-gradient(
        54% 50%,
        #{saturate(lighten($key-color, 10%), 8%)} 54%,
        #{$key-color} 100%
      );
    }
    .front-cover-image {
      background-image: url("./assets/img/front-cover--the-vault-of-design@2x.png");
    }
  }
</style>
